<template>
    <div
        class="live-display full-height full-width overflow-hidden d-flex flex-column"
        :style="bg($vuetify.theme.themes.dark.background)"
    >
        <template v-if="loading">
            <div class="full-height full-width d-flex align-center justify-center">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </div>
        </template>

        <template v-else>
            <div class="overflow-hidden full-height full-width flex-shrink-1">
                <template v-if="active && active._id">
                    <v-container
                        fluid
                        class="full-width full-height d-flex flex-column overflow-hidden p-relative pa-6"
                    >
                        <div class="p-absolute top left px-6 zindex-10">
                            <h1 class="white--text">{{ entryIndex + 1 }}</h1>
                        </div>

                        <div class="p-absolute top right px-6 zindex-10">
                            <v-switch
                                hide-details
                                v-model="toggleJudges"
                                dark
                                color="primary"
                                label="Toggle Judges"
                            ></v-switch>
                        </div>

                        <v-img
                            v-if="!active.data.videoSourceFull"
                            class="rounded-lg full-width full-height flex-shrink-1"
                            :style="bg($vuetify.theme.themes.dark.background)"
                            :src="activeImage"
                            :key="activeImage"
                            alt="avatar"
                            contain
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    :style="bg($vuetify.theme.themes.dark.background)"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        :width="2"
                                        :size="20"
                                        color="dark"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <template v-else-if="entry.data.videoSourceFull">
                            <div class="select-container">
                                <v-select
                                    v-model="selectedVideo"
                                    :items="videos"
                                    align-center
                                    justify-center
                                    class="custom-select"
                                    item-text="label"
                                ></v-select>
                            </div>

                            <iframe
                                class="light full-width full-height cursor-pointer"
                                v-if="videoSource"
                                width="100%"
                                height="315"
                                :src="videoSource"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </template>
                        <template v-if="toggleJudges">
                            <div class="tiles mt-3">
                                <div class="tile-outer" v-for="judge in judges" :key="judge.chair">
                                    <judge-item :judge="judge" display></judge-item>
                                </div>

                                <template v-if="currentScoreCard && !contested && !restarted">
                                    <!-- <div class="tile-outer">
                                        <v-card height="135" class="elevation-0 text-center display rounded-lg pa-6 full-width d-flex flex-column align-center justify-center" v-if="currentScoreCard.data && currentScoreCard.data.round">
                                            <p class="ma-0">Round</p>
                                            <h1>{{ currentScoreCard.data.round }}</h1>
                                        </v-card>
                                    </div> -->

                                    <template v-if="currentScoreCard.data && currentScoreCard.data.modified">
                                        <div class="tile-outer">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center display rounded-lg pa-6 full-width d-flex flex-column align-center justify-center"
                                            >
                                                <p class="ma-0">Modified Score</p>
                                                <h1>{{ currentScoreCard.data.modified }}</h1>
                                            </v-card>
                                        </div>
                                    </template>

                                    <template v-else-if="currentScoreCard.data && currentScoreCard.data.calculated">
                                        <div class="tile-outer">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center display rounded-lg pa-6 full-width d-flex flex-column align-center justify-center"
                                            >
                                                <p class="ma-0">Score</p>
                                                <h1>{{ currentScoreCard.data.calculated }}</h1>
                                            </v-card>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </v-container>
                </template>

                <template v-else>
                    <flicking ref="flicking" :options="options">
                        <div class="full-height full-width p-relative" v-for="(entry, index) in entries" :key="index">
                            <div class="p-absolute top left pa-6 zindex-10">
                                <h1 class="white--text">{{ index + 1 }}</h1>
                            </div>

                            <template v-if="hasImage(entry)">
                                <v-img
                                    class="rounded-lg full-width full-height"
                                    :style="bg($vuetify.theme.themes.dark.background)"
                                    :src="$fluro.asset.imageUrl(entry.data.image)"
                                    contain
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            :style="bg($vuetify.theme.themes.dark.background)"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                :width="2"
                                                :size="20"
                                                color="dark"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </template>
                            <template v-else-if="entry.data.videoSourceFull">
                                <div class="select-container">
                                    <v-select
                                        v-model="selectedVideo"
                                        :items="videos"
                                        align-center
                                        justify-center
                                        class="custom-select"
                                        item-text="label"
                                    ></v-select>
                                </div>

                                <iframe
                                    class="light full-width full-height cursor-pointer"
                                    v-if="videoSource"
                                    width="100%"
                                    height="315"
                                    :src="videoSource"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </template>
                            <template v-else>
                                <v-row
                                    class="rounded-lg fill-height full-width grey ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                                </v-row>
                            </template>
                        </div>
                    </flicking>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import Room from "@/modules/app-module/room";
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";

import { Flicking } from "@egjs/vue-flicking";
// import "@egjs/flicking/dist/flicking-inline.css";

import JudgeItem from "@/components/scribe/judge-item.vue";

export default {
    name: "live-display",

    meta() {
        return {
            title: this.room?.title || "Room",
        };
    },

    components: {
        JudgeItem,
        Flicking,
    },

    data() {
        return {
            videos: [{ label: "Short Video" }, { label: "Full Video" }],
            selectedVideo: "Short Video", // default selection
            socket: null,
            socketName: "Display" + Math.random().toString(36),
            timeout: 5000,
            flickingTimeout: 3000,
            loading: false,
            room: {},
            entry: {},
            scorecards: [],
            poll: null,
            slideshow: null,
            isPlaying: false,
            toggleJudges: true,
            options: {
                horizontal: true,
                align: "center",
                panelsPerView: 1,
                circular: true,
                disableOnInit: true,
            },
        };
    },

    computed: {
        videoSource() {
            return this.selectedVideo == "Short Video"
                ? this.entry.data.videoSourceShort
                : this.entry.data.videoSourceFull;
        },
        active() {
            return this?.room?.data?.active || null;
        },
        entries() {
            return this?.room?.data?.entries || [];
        },
        entryIndex() {
            if (!this.active?._id) return;

            return this.entries.findIndex((entry) => entry._id == this.active._id);
        },
        image() {
            return this.active?.data?.image || null;
        },
        activeImage() {
            return this.$fluro.asset.imageUrl(this.image, null, null, { quality: 100 });
        },
        limit() {
            return this?.room?.data?.limit || 0;
        },
        round() {
            let scorecards = this.scorecards;

            scorecards = scorecards.filter((card) => card?.data?.contested || card?.data?.restarted);

            return scorecards.length + 1;
        },
        activeJudges() {
            return this.room?.data?.activeJudges || [];
        },
        judges() {
            let limit = this.room?.data?.limit || 0;
            let array = [];

            for (let index = 1; index <= limit; index++) {
                let judge = this.activeJudges.find((item) => item.chair == index);

                let score = null;

                if (judge) {
                    if (this.entry?._id) {
                        let scores = this.entry?.posts?.score?.posts || [];

                        score = scores.find(
                            (item) => item?.managedAuthor == judge?.judge?._id && item.data.round == this.round
                        );
                    }

                    array.push({ ...judge, score });
                } else {
                    array.push({
                        score: null,
                        judge: null,
                        chair: index,
                    });
                }
            }

            return array;
        },
        currentScoreCard() {
            let scorecards = this.scorecards || [];

            if (scorecards?.length) {
                return scorecards.sort((a, b) => b?.data?.round - a?.data?.round)[0];
                // return scorecards.find((card) => !card.data.completed && !card.data.contested);
            }

            return false;
        },
        completed() {
            return this.currentScoreCard?.data?.completed;
        },
        contested() {
            return this.currentScoreCard?.data?.contested;
        },
        restarted() {
            return this.currentScoreCard?.data?.restarted;
        },
    },

    methods: {
        /////////////////////////START SOCKET HANDELING////////////////////////////
        async socketSetup() {
            console.log("Starting Socket Test");
            this.socket = new WebSocket(
                `wss://smhb2elzv9.execute-api.ap-southeast-2.amazonaws.com/production?persona=${this.$app.user.persona}&room=${this.$route.params.room}`
            );

            console.log("SOCKET", this.socket);
            this.socket.onopen = () => {
                console.log("Connected to WebSocket.");

                // Send a join command/message to the server
                const joinMessage = {
                    command: "join",
                    room: this.$route.params.room,
                    persona: this.$app.user.persona,
                    name: this.socketName || "Display",
                };
                console.log("MESSAGE", joinMessage);
                this.socket.send(JSON.stringify(joinMessage));
                this.startPing();
            };

            this.socket.onmessage = async (event) => {
                console.log("Received data from WebSocket: ", event.data);

                const data = JSON.parse(event.data);

                if (data.action == "refresh" || data.action == "submit") {
                    this.setup();
                } else if (data.action == "create") {
                    await this.getRoomScribe();
                }
            };

            this.socket.onclose = () => {
                console.log("Disconnected from WebSocket.");
                this.stopPing();
            };

            this.socket.onerror = (error) => {
                console.error("WebSocket error: ", error);
                this.stopPing();
            };
        },
        closeWebSocket() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
        },
        startPing() {
            this.pingInterval = setInterval(() => {
                if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                    const createMessage = {
                        command: "pingScribe",
                        name: this.socketName,
                        persona: this.$app.user.persona,
                        scribeConnection: this.scribeConnection,
                    };
                    this.socket.send(JSON.stringify(createMessage));
                }
            }, 1500); // Ping interval in milliseconds
        },
        stopPing() {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        },
        async getRoomScribe() {
            //Get All detail Sheets
            const roomQuery = {
                definition: "socket",
                status: "active",
                "data.room": this.$route.params.room,
                // "data.activeOrgs": { $in: [currentOrg] }, //TO DO, make this work to increase performance
            };
            console.log(this.$route.params.room);
            const response = await this.$fluro.api.post("/content/_query", roomQuery).then(({ data }) => data);
            console.log(response);
            this.scribeConnection = response[0].data.scribe;
        },
        /////////////////////////END SOCKET HANDELING////////////////////////////
        async init() {
            this.loading = true;
            await this.socketSetup();
            try {
                await this.setup();
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        async setup() {
            // clearTimeout(this.poll);

            await this.$fluro.resetCache();

            this.room = await Room.get(this.$route.params.room).then(({ data }) => data);
            console.log("ROOM", this.room);

            //////////////////////////////////////////////////

            // let user = this.$app.user;
            // let scribe = this.room?.data?.scribe;
            // let scribeId = this.$route.params.scribe;

            // if (user.persona == scribe._id && user.persona == scribeId) {
            if (this.active?._id) {
                clearTimeout(this.slideshow);
                this.isPlaying = false;

                await this.refreshScores();
                await this.refreshScoreCard();
            } else {
                console.log("NO ACTIVE ENTRY");

                if (!this.isPlaying) {
                    this.slideshow = setTimeout(this.play, this.flickingTimeout);
                }
            }
            // this.poll = setTimeout(this.setup, this.timeout);
            // } else {
            //     this.$router
            //         .push({
            //             name: "live-competitions-room",
            //             params: {
            //                 competition: this.$route.params.competition,
            //                 category: this.$route.params.category,
            //                 room: this.$route.params.room,
            //             },
            //         })
            //         .catch(() => {});
            // }

            this.loading = false;
        },
        async refreshScores() {
            try {
                this.entry = await Entry.get(this.active._id, { params: { appendPosts: "all" } }).then(
                    ({ data }) => data
                );
                console.log("ACTIVE", this.entry);
            } catch (err) {
                console.log("REFRESH ERROR", err);
            }
        },
        async refreshScoreCard() {
            try {
                let query = {
                    _type: "article",
                    definition: "scoreCard",
                    "data.entry": `ObjectId(${this.active._id})`,
                };

                this.scorecards = await ScoreCard.query(query).then(({ data }) => data);
                console.log("SCORE CARDS", this.scorecards);
            } catch (err) {
                console.log("REFRESH SCORE CARD ERROR", err);
            }
        },
        bg(color) {
            return {
                backgroundColor: color,
            };
        },
        hasImage(item) {
            let image = item && item.data && item.data.image;

            return image?._id || image;
        },
        play() {
            this.isPlaying = true;

            console.log("PLAY");

            this.$nextTick(() => {
                clearTimeout(this.slideshow);

                this.$refs.flicking.next();

                this.slideshow = setTimeout(() => this.play(), this.flickingTimeout);
            });
        },
    },

    async mounted() {
        await this.init();
        await this.getRoomScribe();
    },

    destroyed() {
        this.closeWebSocket();
        // clearTimeout(this.poll);
        clearTimeout(this.slideshow);
    },
};
</script>

<style lang="scss">
.live-display {
    .tiles {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin: -12px;
        justify-content: center;

        .tile-outer {
            width: 100%;
            padding: 12px;
            flex: 0 0 calc(100% / 8);
            max-width: calc(100% / 8);
        }
    }

    .display {
        background-color: #333333 !important;

        b,
        p,
        h1 {
            color: #ffffff !important;
        }
    }

    .flicking-viewport {
        // width: 100%;
        height: 100% !important;
    }
}

.flicking-viewport {
    position: relative;
    overflow: hidden;
}

.flicking-viewport:not(.vertical) {
    width: 100%;
    height: 100%;
}

.flicking-viewport.vertical,
.flicking-viewport.vertical > .flicking-camera {
    display: inline-block;
}

.flicking-viewport.vertical.middle > .flicking-camera > * {
    vertical-align: middle;
}

.flicking-viewport.vertical.bottom > .flicking-camera > * {
    vertical-align: bottom;
}

.flicking-viewport.vertical > .flicking-camera > * {
    display: block;
}

.flicking-viewport.flicking-hidden > .flicking-camera > * {
    visibility: hidden;
}

.flicking-camera {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    will-change: transform;
}

.flicking-camera > * {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
}
</style>
